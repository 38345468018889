"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isStringValidCharlyInput = exports.anamesePatientPrivateEmailField = exports.anamnesePreviousPatientStore = exports.anamnesePatientStore = void 0;
const lodash_1 = require("lodash");
const vue_demi_1 = require("vue-demi");
const types_1 = require("../../../../types");
const nanoid_1 = require("../../nanoid");
const anamneseCharlyConfigStore_1 = require("./anamneseCharlyConfigStore");
exports.anamnesePatientStore = createPatientStore();
exports.anamnesePreviousPatientStore = createPatientStore();
// @ts-ignore
window.anamnesePreviousPatientStore = exports.anamnesePreviousPatientStore;
exports.anamesePatientPrivateEmailField = {
    fieldId: 'contacts.email',
    field: 'contacts',
    label: 'Email',
    type: 'contact',
    contactState: 'PRIVATE',
    contactType: 'EMAIL',
    inputType: 'email',
};
function createPatientStore() {
    return (0, vue_demi_1.reactive)({
        state: {
            // charly patient details
            initialPatientDetails: null,
            // charly patient details to be edited
            patientDetails: null,
            highlightField: null,
            get availableFields() {
                return [
                    // person
                    {
                        fieldId: 'title',
                        field: 'title',
                        label: 'Titel',
                        type: 'valueSet',
                        valueSet: anamneseCharlyConfigStore_1.anamneseCharlyConfigStore.state.titles,
                        valueSetAllowEmpty: true,
                        fullWidth: true,
                    },
                    { fieldId: 'firstName', field: 'firstName', label: 'Vorname', type: 'text' },
                    { fieldId: 'lastName', field: 'lastName', label: 'Nachname', type: 'text' },
                    { fieldId: 'birthday', field: 'birthday', label: 'Geburtstag', type: 'date' },
                    {
                        fieldId: 'gender',
                        field: 'gender',
                        label: 'Geschlecht',
                        type: 'valueSet',
                        valueSet: types_1.charlyGenderValueSet,
                    },
                    // adresse
                    {
                        fieldId: 'street',
                        field: 'street',
                        label: 'Straße, Hausnummer',
                        type: 'text',
                        placeholder: 'z.B. Musterstraße 123',
                        fullWidth: true,
                    },
                    {
                        fieldId: 'zip',
                        field: 'zip',
                        label: 'Postleitzahl',
                        type: 'text',
                        inputType: 'number',
                        pattern: '[0-9]*',
                    },
                    { fieldId: 'city', field: 'city', label: 'Stadt', type: 'text' },
                    {
                        fieldId: 'country',
                        field: 'country',
                        label: 'Land',
                        type: 'valueSet',
                        valueSet: anamneseCharlyConfigStore_1.anamneseCharlyConfigStore.state.countries,
                    },
                    { fieldId: 'birthplace', field: 'birthplace', label: 'Geburtsort', type: 'text' },
                    {
                        fieldId: 'contacts.handy',
                        field: 'contacts',
                        label: 'Mobilfunk',
                        type: 'contact',
                        contactState: 'PRIVATE',
                        contactType: 'MOBILE',
                        inputType: 'tel',
                    },
                    {
                        fieldId: 'contacts.telefon',
                        field: 'contacts',
                        label: 'Festnetz',
                        type: 'contact',
                        contactState: 'PRIVATE',
                        contactType: 'PHONE',
                        inputType: 'tel',
                    },
                    exports.anamesePatientPrivateEmailField,
                ];
            },
            get questionnaireFields() {
                return this.availableFields
                    .filter(field => { var _a, _b; return ((_b = (_a = this.fieldConfig) === null || _a === void 0 ? void 0 : _a[field.fieldId]) === null || _b === void 0 ? void 0 : _b.hideField) !== true; })
                    .map(field => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, field), { optional: field.optional || ((_b = (_a = this.fieldConfig) === null || _a === void 0 ? void 0 : _a[field.fieldId]) === null || _b === void 0 ? void 0 : _b.optional) === true }));
                });
            },
            fieldConfig: null,
        },
        setPatientDetails(patientDetails) {
            this.state.patientDetails = patientDetails || null;
        },
        setInitialPatientDetails(patientDetails) {
            this.state.initialPatientDetails = patientDetails;
        },
        setFieldConfig(fieldConfig) {
            this.state.fieldConfig = fieldConfig;
        },
        getPatientDetails() {
            return this.state.patientDetails;
        },
        clearPatientDetails() {
            this.state.patientDetails = null;
            this.state.highlightField = null;
        },
        hightlightValidationError() {
            this.state.highlightField = this.firstInvalidField();
        },
        getFieldIdValue(fieldId, overridePatientDetails) {
            let field = this.state.questionnaireFields.find(f => f.fieldId === fieldId);
            if (field) {
                return this.getFieldValue(field, overridePatientDetails);
            }
        },
        getFieldValue(field, overridePatientDetails) {
            var _a;
            let patDetails = overridePatientDetails || this.state.patientDetails;
            if (field.type === 'contact') {
                let contact = (_a = patDetails === null || patDetails === void 0 ? void 0 : patDetails.contacts) === null || _a === void 0 ? void 0 : _a.find(c => c.state === field.contactState && c.type === field.contactType);
                return contact === null || contact === void 0 ? void 0 : contact.value;
            }
            return patDetails === null || patDetails === void 0 ? void 0 : patDetails[field.field];
        },
        setFieldIdValue(fieldId, value) {
            let field = this.state.questionnaireFields.find(f => f.fieldId === fieldId);
            if (field) {
                this.setFieldValue(field, value);
            }
        },
        setFieldValue(field, value) {
            var _a;
            this.state.patientDetails = this.state.patientDetails || {};
            if (field.type === 'contact') {
                let contacts = ((_a = this.state.patientDetails) === null || _a === void 0 ? void 0 : _a.contacts) || [];
                let contact = contacts.find(c => c.state === field.contactState && c.type === field.contactType);
                if (contact) {
                    contact.value = value;
                }
                else {
                    contacts.push({
                        id: (0, nanoid_1.roseNanoidNumeric)(),
                        state: field.contactState,
                        type: field.contactType,
                        value,
                    });
                }
                (0, vue_demi_1.set)(this.state.patientDetails, field.field, contacts);
            }
            else {
                (0, vue_demi_1.set)(this.state.patientDetails, field.field, value);
            }
        },
        hasFieldValidAnswer(field) {
            let fieldValue = this.getFieldValue(field);
            if (isStringValidCharlyInput(fieldValue).valid === false) {
                return false;
            }
            if (field.optional || field.valueSetAllowEmpty) {
                return true;
            }
            let telefoneFieldIds = ['contacts.handy', 'contacts.telefon'];
            if (telefoneFieldIds.includes(field.fieldId)) {
                return this.state.questionnaireFields
                    .filter(f => telefoneFieldIds.includes(f.fieldId))
                    .some(f => this.getFieldValue(f));
            }
            if (!fieldValue) {
                return false;
            }
            return true;
        },
        firstInvalidField() {
            return this.state.questionnaireFields.find(field => this.hasFieldValidAnswer(field) === false);
        },
        hasValidPatientDetails() {
            return !this.firstInvalidField();
        },
    });
}
function isStringValidCharlyInput(input) {
    // only allow common characters, return error message with invalid characters
    const invalidChars = (input || '').match(/[^a-zA-Z0-9äöüÄÖÜß\-_().:\s%!/<>"'&,+=*?;@#$§€^éèàùâêîôûçëï]/g);
    // charly converts these to "_":
    // ϋ
    if (!(0, lodash_1.isEmpty)(invalidChars)) {
        // make invalidChars unique
        const uniqInvalidChars = (0, lodash_1.uniq)(invalidChars);
        return { valid: false, errorMsg: `Ungültige Zeichen: ${uniqInvalidChars.join(', ')}` };
    }
    return { valid: true };
}
exports.isStringValidCharlyInput = isStringValidCharlyInput;
