"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.anamneseCharlyConfigStore = void 0;
const vue_demi_1 = require("vue-demi");
exports.anamneseCharlyConfigStore = (0, vue_demi_1.reactive)({
    state: {
        charlyAnamneseConfig: null,
        get affixes() {
            var _a;
            return ((_a = this.charlyAnamneseConfig) === null || _a === void 0 ? void 0 : _a.affixes.map(c => c.nameAffix)) || [];
        },
        get countries() {
            if (!this.charlyAnamneseConfig || !this.charlyAnamneseConfig.countries) {
                return [];
            }
            return Array.from(new Set(this.charlyAnamneseConfig.countries.map(c => c.country).filter(c => !!c)));
        },
        get peerages() {
            var _a;
            return ((_a = this.charlyAnamneseConfig) === null || _a === void 0 ? void 0 : _a.peerages.map(c => c.peerage)) || [];
        },
        get titles() {
            var _a;
            return ((_a = this.charlyAnamneseConfig) === null || _a === void 0 ? void 0 : _a.titles.map(c => c.title)) || [];
        },
        get charlyQuestions() {
            var _a;
            return ((_a = this.charlyAnamneseConfig) === null || _a === void 0 ? void 0 : _a.questions) || [];
        },
    },
    setCharlyAnamneseConfig(charlyAnamneseConfig) {
        this.state.charlyAnamneseConfig = charlyAnamneseConfig;
    },
    getCharlyQuestionById(id) {
        return this.state.charlyQuestions.find(q => q.questionID === id);
    },
});
// @ts-ignore
window.anamneseCharlyConfigStore = exports.anamneseCharlyConfigStore;
